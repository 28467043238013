import {createRouter, createWebHashHistory} from 'vue-router'

// 公共路由
export const constantRoutes = [
    {
        path:'/',
        redirect:'/sparkIndex'
    },
    {
        name: "首页",
        path: '/sparkIndex',
        component: () => import('@/components/sparkIndex.vue')
    },
    {
        name: "tiktok列表-父组件",
        path: '/tiktokRank',
        component: () => import('@/components/tiktok/tiktokRank.vue')
    },
    {
        name: "个人中心",
        path: '/personalCenter',
        component: () => import('@/components/personalCenter.vue')
    },
    {
        name: "关于我们",
        path: '/aboutUS',
        component: () => import('@/components/aboutUs.vue')
    },
    {
        name: "会员",
        path: '/userVip',
        component: () => import('@/components/vip.vue')
    },
    {
        name: "达人列表-子组件",
        path: '/tiktokBloggerList',
        component: () => import('@/components/tiktok/tiktokBloggerList.vue')
    },
    {
        name: "达人列表-PK结果",
        path: '/tiktokBloggerPKList',
        component: () => import('@/components/tiktok/tiktokBloggerPKList.vue')
    },
    {
        name: "带货达人榜-数据",
        path: '/tiktokBloggerLiveList',
        component: () => import('@/components/tiktok/tiktokBloggerLiveList.vue')
    },
    {
        name: "涨粉达人榜-数据",
        path: '/tiktokBloggerRiseList',
        component: () => import('@/components/tiktok/tiktokBloggerRiseList.vue')
    },
    {
        name: "MCN机构榜-数据",
        path: '/tiktokBloggerMCNList',
        component: () => import('@/components/tiktok/tiktokBloggerMCNList.vue')
    },
    {
        name: "达人分析面板",
        path: '/tiktokBloggerDetail',
        component: () => import('@/components/tiktok/tiktokBloggerDetail.vue')
    },
    {
        name: "达人分析面板-基础分析",
        path: '/bloggerAnalysisBase',
        component: () => import('@/components/tiktok/bloggerAnalysisBase.vue')
    },
    {
        name: "达人分析面板-直播分析",
        path: '/bloggerAnalysisLive',
        component: () => import('@/components/tiktok/bloggerAnalysisLive.vue')
    },
    {
        name: "达人分析面板-视频分析",
        path: '/bloggerAnalysisVideo',
        component: () => import('@/components/tiktok/bloggerAnalysisVideo.vue')
    },
    {
        name: "达人分析面板-带货分析",
        path: '/bloggerAnalysisCommerce',
        component: () => import('@/components/tiktok/bloggerAnalysisCommerce.vue')
    },
    {
        name: "达人分析面板-粉丝分析",
        path: '/bloggerAnalysisFans',
        component: () => import('@/components/tiktok/bloggerAnalysisFans.vue')
    },
    {
        name: "视频库列表-数据",
        path: '/tiktokVideoList',
        component: () => import('@/components/tiktok/tiktokVideoList.vue')
    },
    {
        name: "带货视频榜-数据",
        path: '/tiktokVideoCommerceList',
        component: () => import('@/components/tiktok/tiktokVideoCommerceList.vue')
    },
    {
        name: "热门视频榜-数据",
        path: '/tiktokVideoHotList',
        component: () => import('@/components/tiktok/tiktokVideoHotList.vue')
    },
    {
        name: "团购视频榜-数据",
        path: '/tiktokVideoGroupBuyList',
        component: () => import('@/components/tiktok/tiktokVideoGroupBuyList.vue')
    },
    {
        name: "视频分析面板",
        path: '/tiktokVideoDetail',
        component: () => import('@/components/tiktok/tiktokVideoDetail.vue')
    },
    {
        name: "视频分析面板-基础分析",
        path: '/videoAnalysisBase',
        component: () => import('@/components/tiktok/videoAnalysisBase.vue')
    },
    {
        name: "视频分析面板-评论分析",
        path: '/videoAnalysisComment',
        component: () => import('@/components/tiktok/videoAnalysisComment.vue')
    },
    {
        name: "视频分析面板-观众分析",
        path: '/videoAnalysisAudience',
        component: () => import('@/components/tiktok/videoAnalysisAudience.vue')
    },
    {
        name: "视频分析面板-视频诊断",
        path: '/videoAnalysisDiagnosis',
        component: () => import('@/components/tiktok/videoAnalysisDiagnosis.vue')
    },
    {
        name: "视频分析面板-对比分析",
        path: '/videoAnalysisContrast',
        component: () => import('@/components/tiktok/videoAnalysisContrast.vue')
    },
    {
        name: "直播库列表-数据",
        path: '/tiktokLiveList',
        component: () => import('@/components/tiktok/tiktokLiveList.vue')
    },
    {
        name: "今日带货榜-数据",
        path: '/tiktokLiveTodayList',
        component: () => import('@/components/tiktok/tiktokLiveTodayList.vue')
    },
    {
        name: "带货小时榜-数据",
        path: '/tiktokLiveHourList',
        component: () => import('@/components/tiktok/tiktokLiveHourList.vue')
    },
    {
        name: "红人看板-数据",
        path: '/tiktokLiveCelebrityList',
        component: () => import('@/components/tiktok/tiktokLiveCelebrityList.vue')
    },
    {
        name: "直播大屏",
        path: '/liveDataCenter',
        component: () => import('@/components/tiktok/liveDataCenter.vue')
    },
    {
        name: "商品库列表-数据",
        path: '/tiktokProductList',
        component: () => import('@/components/tiktok/tiktokProductList.vue')
    },
    {
        name: "销量榜-数据",
        path: '/tiktokProductRankList',
        component: () => import('@/components/tiktok/tiktokProductRankList.vue')
    },
    {
        name: "热推榜-数据",
        path: '/tiktokProductHotList',
        component: () => import('@/components/tiktok/tiktokProductHotList.vue')
    },
    {
        name: "商品分析面板",
        path: '/tiktokProductDetail',
        component: () => import('@/components/tiktok/tiktokProductDetail.vue')
    },
    {
        name: "商品分析面板-基础分析",
        path: '/productAnalysisBase',
        component: () => import('@/components/tiktok/productAnalysisBase.vue')
    },
    {
        name: "商品分析面板-达人分析",
        path: '/productAnalysisBlogger',
        component: () => import('@/components/tiktok/productAnalysisBlogger.vue')
    },
    {
        name: "商品分析面板-直播分析",
        path: '/productAnalysisLive',
        component: () => import('@/components/tiktok/productAnalysisLive.vue')
    },
    {
        name: "商品分析面板-视频分析",
        path: '/productAnalysisVideo',
        component: () => import('@/components/tiktok/productAnalysisVideo.vue')
    },
    {
        name: "商品分析面板-受众分析",
        path: '/productAnalysisAudience',
        component: () => import('@/components/tiktok/productAnalysisAudience.vue')
    },
    {
        name: "小店库列表-数据",
        path: '/tiktokShopList',
        component: () => import('@/components/tiktok/tiktokShopList.vue')
    },
    {
        name: "小店排行榜-数据",
        path: '/tiktokShopRankList',
        component: () => import('@/components/tiktok/tiktokShopRankList.vue')
    },
    {
        name: "小店排行榜-数据",
        path: '/tiktokShopBrandRankList',
        component: () => import('@/components/tiktok/tiktokShopBrandRankList.vue')
    },
    {
        name: "个人中心-个人数据",
        path: '/personalMain',
        component: () => import('@/components/personal/personalMain.vue')
    },
    {
        name: "个人中心-购买续费",
        path: '/buyVip',
        component: () => import('@/components/personal/buyVip.vue')
    },
    {
        name: "个人中心-我的订单",
        path: '/order',
        component: () => import('@/components/personal/order.vue')
    },
    {
        name: "个人中心-达人收藏",
        path: '/bloggerCollect',
        component: () => import('@/components/personal/bloggerCollect.vue')
    },
    {
        name: "个人中心-视频收藏",
        path: '/videoCollect',
        component: () => import('@/components/personal/videoCollect.vue')
    },
    {
        name: "个人中心-商品收藏",
        path: '/shopCollect',
        component: () => import('@/components/personal/shopCollect.vue')
    },
    {
        name: "个人中心-我的达人",
        path: '/myBloggers',
        component: () => import('@/components/personal/myBloggers.vue')
    },
    {
        name: "个人中心-达人公海",
        path: '/findBloggers',
        component: () => import('@/components/personal/findBloggers.vue')
    },
    {
        name: "个人中心-商品库",
        path: '/products',
        component: () => import('@/components/personal/products.vue')
    },
    {
        name: "个人中心-商品分类",
        path: '/productClass',
        component: () => import('@/components/personal/productClass.vue')
    },
    {
        name: "购买续费页",
        path: '/buyVipPop',
        component: () => import('@/components/buyVipPop.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes
})

export default router