export default {
    nation: {
        jpn: '日本',
        japan: '日本',
        indonesia: '印尼',
        america: '美国',
        britain: '英国',
        vietnam: '越南',
        thailand: '泰国'
    },
    loading: '统计中...',
    noData: '暂无数据',
    head: {
        TikTok: 'TikTok分析',
        Instagram: 'Instagram分析',
        YouTube: 'YouTube分析',
        douyin: '抖音分析',
        kuaishou: '快手分析',
        xiaohongshu: '小红书分析',
        aboutUs: '关于我们',
        personalCenter: '个人中心',
        blogger: '找达人',
        bloggerText: '对接全网达人',
        live: '找直播',
        liveText: '热门直播间',
        product: '找商品',
        productText: '发现高佣金爆款',
        video: '找视频',
        videoText: '涨粉最多销量最高',
        shop: '找小店',
        shopText: '全球销量最高',
        lang: '中文',
        chinese: '中文',
        japanese: '日文',
        english: '英语',
        indonesian: '印度尼西亚语',
        registerAndLogin: '注册/登录',
        purchaseRenewal: '购买续费',
        myCollection: '我的收藏',
        personalInformation: '个人资料及认证',
        helps: '帮助中心'
    },
    login: {
        login: '密码登录',
        register: '欢迎注册',
        registerEmail: '请输入邮箱',
        registerPassword: '请输入密码',
        toLogin: '登录',
        sendCode: '获取验证码',
        registerSmsCode: '请输入邮箱验证码',
        registerCompany: '您的公司名称',
        registerPhone: '联系电话',
        registerVerified: '请再次输入密码',
        toRegister: '完成注册'
    },
    index: {
        TikTok: 'TikTok',
        Instagram: 'Instagram',
        YouTube: 'YouTube',
        douyin: '抖音',
        kuaishou: '快手',
        xiaohongshu: '小红书',
        mainDiv1_2_l: '短视频、直播带货、全网达人',
        mainDiv1_2_r: '数据分析平台',
        mainSearch: '搜索达人/商品/直播/小店/视频/品牌',
        mainDiv1_4: '直播复盘·营销诊断',
        mainDiv1_5_1: '直播电商运营管理工具，化身你的直播间管家，',
        mainDiv1_5_2: '可以实时追踪流量投放、商品分析、行业对比等全方位指标，',
        mainDiv1_5_3: '并通过录屏回放帮助复盘诊断直播全程；',
        mainDiv1_5_4: '更有AI排班、主播绩效等功能，助力高效管理直播团队',
        mainDiv2_head: '今日直播趋势',
        saleNumHeat: '销售额热度',
        audienceHeat: '观众热度',
        liveHeat: '直播间热度',
        productHeat: '商品热度',
        saleHeat: '销售热度',
        mainDiv3_title: '选品带货·挖掘爆款',
        mainDiv3_1: '选品带货',
        mainDiv3_2: '在线高佣爆品',
        mainDiv3_3: '成交订单',
        mainDiv4_title: '全场景营销服务',
        mainDiv4_1eft_1_1: '基础',
        mainDiv4_1eft_1_2: '数据达人',
        mainDiv4_1eft_2_2: '商品数据',
        mainDiv4_1eft_3_1: '支持',
        mainDiv4_1eft_3_2: '直播数据',
        mainDiv4_1eft_4_2: '带货视频',
        mainDiv4_1eft_5: '稳定更新',
        mainDiv4_right_title_1: '基础带货视频',
        mainDiv4_right_text_1: '查看数千万短视频平台在售商品信息，包括商品基本信息，基本销量，评论信息以及商品合作的达人，在售的店铺等',
        mainDiv4_right_title_2: '支持直播数据',
        mainDiv4_right_text_2: '支持对指定直播间的索引和更新',
        mainDiv4_right_title_3: '基础商品数据',
        mainDiv4_right_text_3: '看数千万的短视频平台的带货素材，包括视频的评价数据、素材本身、所使用的音乐、话题，支持各种维度筛选',
        mainDiv4_right_title_4: '稳定更新',
        mainDiv4_right_text_4: '千万级别稳定日更，可根据需要查询对应的短视频、直播间等相关数据',
        mainDiv4_right_title_5: '基础数据达人',
        mainDiv4_right_text_5: '查看短视频平台已开通的达人信息，包括粉丝情况、内容发布记录、内容互动率、历史带货记录支持各种维度筛选查阅各国网红达人榜单等',
    },
    left: {
        blogger: '达人库',
        bloggerLive: '带货达人榜',
        bloggerRise: '涨粉达人榜',
        bloggerMCN: 'MCN机构榜',
        video: '视频库',
        videoCommerce: '带货视频榜',
        videoHot: '热门视频榜',
        videoGroupBuy: '团购视频榜',
        live: '直播库',
        liveToday: '今日带货榜',
        liveHour: '带货小时榜',
        liveCelebrity: '红人看板',
        product: '商品库',
        productRank: '销量榜',
        productHot: '热推榜',
        shop: '小店库',
        shopRank: '小店排行榜',
        shopBrandRank: '品牌小店排行榜',
    },
    aboutUs: {
        mainDiv1: '提供有价值的洞察和智能化的解决方案',
        mainDiv2_1: '关于我们',
        mainDiv2_2: '　　我们是一家专注于短视频数据分析的公司。我们的使命是帮助客户解析和利用短视频平台上海量的数据，提供有价值的洞察和智能化的解决方案。 作为数据分析领域的专业团队，我们拥有丰富的经验和深厚的专业知识。',
        mainDiv2_3: '　　我们深入了解短视频平台的生态系统，掌握最新的技术和工具，能够准确捕捉和解读数据中的关键信息。 我们的核心优势在于数据分析和洞察力。',
        mainDiv2_4: '　　我们运用先进的算法和技术，对短视频平台上的数据进行深度挖掘和分析，揭示出潜在的趋势、用户行为和市场机会。',
        MainDiv3_1: '我们的分析报告和可视化工具能够帮助客户更好地理解数据，作出明智的决策，并优化业务策略。',
        MainDiv3_2: '我们以客户为中心，注重与客户的合作和沟通。',
        MainDiv3_3: '我们深入了解客户的需求和目标，为客户量身定制解决方案，并提供专业的建议和支持。我们致力于建立长期的合作关系，与客户共同实现业务增长和成功。',
        MainDiv3_4: '如果您正在寻找一家专业的短视频数据分析合作伙伴，我们将是您的理想选择。',
        MainDiv3_5: '请与我们联系，让我们一起开启数据驱动的洞察之旅！',
    },
    personalCenter: {
        title: '个人中心',
        tab1: '个人资料及认证',
        tab2: '我的收藏',
        tab2_1: '达人收藏',
        tab2_2: '视频收藏',
        tab2_3: '商品收藏',
        tab2_4: '话题收藏',
        tab3: '视频库',
        tab4: '直播库',
        tab5: '商品库',
        tab6: '小店库',
        tab7: '达人管理',
        tab8: '商品管理',
        personalMainTitle: '个人资料',
        emailBinding: '邮箱绑定',
        toBind: '换绑',
        phoneBind: '手机绑定',
        password: '登录密码',
        change: '修改',
        purchaseRenewal: '购买续费',
        myOrder: '我的订单',
        myBloggers: '我的达人',
        bloggerList: '达人公海',
        productList: '商品库',
        category: '商品分类'
    },
    tiktok: {
        like: '点赞',
        comment: '评论',
        share: '转发',
        collect: '收藏',
        sales: '销量',
        salesNum: '销售额',
        hour: '小时',
        yesterday: '昨天',
        day: '天',
        add: '添加',
        addVideo: '添加视频',
        GPM: '千次成交额',
        IPM: '千次浏览互动量',
        liveDataCenter: {
            fans: '粉丝',
            liveDate: '开播时间',
            liveTime: '直播时长',
            lookingNum: '实时观看',
            UVDate: 'UV价值',
            stuckAvg: '平均停留',
            fansIncrease: '累计涨粉',
            powderConversionRate: '转粉率',
            productNum: '商品数',
            saleTotal: '总销量',
            product: '商品',
            price: '直播价',
            liveWatchSum: '累计观看人次',
        },
        class: {
            nation: '国家地区',
            blogger: '达人分类',
            product: '商品分类',
            fans: '粉丝区间',
            productNum: '带货数量',
            productVideoNum: '带货视频数',
            listSelection: '榜单选择',
            conditionalSelection: '条件筛选',
            otherSelection: '其他筛选',
            audience: '观众画像',
            sortType: '排序方式',
            timeSelection: '时间筛选',
            productInfo: '商品信息',
            commissionInfo: '佣金信息',
            carryingInfo: '带货信息',
            carryingType: '带货方式',
            to: '至',
            startDate: '开始时间',
            endDate: '结束时间',
            operate: '操作',
            min: '最小值',
            max: '最大值',
            dateSearchPlaceholder: '选择日期',
            day: '日榜',
            week: '周榜',
            month: '月榜',
            recentDetails: '近期详情',
            getTouch: '取得联系',
            searchPlaceholder: '请输入内容',
            sex: '性别',
            age: '年龄',
            fan: '粉丝',
            sales: '商品销量',
            detail: '详情',
            openVideo: '播放',
            bySales: '按销量',
            bySalesNum: '按销售额',
            byLike: '按点赞',
            byDefault: '按综合',
            liveSale: '带货直播',
            liveGroup: '团购直播',
            newStar: '新秀',
            firstRank: '首次上榜',
            darkHorse: '黑马',
            unlimited: '不限',
            freeShipping: '包邮',
            coupon: '有优惠券',
            commissionRate: '佣金比例',
            pageView: '浏览量',
            typeSales: '销量',
            favorableRate: '好评率',
            relatedExperts: '关联达人',
            audiencePortrait: '受众画像',
            mainlyLiveDelivery: '直播带货为主',
            mainlyVideoDelivery: '视频带货为主',
            lessThan1W: '小于1W'
        },
        blogger: {
            search: '达人搜索',
            searchPlaceholder: '请输入内容',
            tabAll: '全部',
            tabLive: '直播达人',
            tabVideo: '视频达人',
            tabProduct: '团购达人',
            listTip: '以下内容为所选类目下达人近30天数据',
            pk: '达人PK',
            clear: '清空',
            toPk: '对比',
            pkDetail: '达人PK详情',
            addBlogger: '添加为意向达人',
            collect: '收藏',
            dataScreening: '数据总览',
            analyze: '详细分析',
            baseAnalyze: '基础分析',
            liveAnalyze: '直播分析',
            videoAnalyze: '视频分析',
            commerceAnalyze: '带货分析',
            fansAnalyze: '粉丝分析',
            fansPortrait: '粉丝画像',
            baseAnalyse: {
                title: '近30天数据概览',
                text1: '近30天，该达人累计开播',
                text2: '场，发布新视频',
                text3: '条， 粉丝增加',
                text4: '。 累计销量达',
                text5: '（直播占',
                text6: ' ）， 30天累计销售额达',
                text7: '（直播占',
                historyLiveNum: '历史总场次',
                baseLiveDateAvg: '平均开播时长',
                baseLiveSalesAvg: '场均销量',
                liveSalesDateAvg: '日均销量',
                talentLiveSales: '直播累计销量',
                baseLiveSalesNumAvg: '场均销售额',
                liveSalesNumDateAvg: '日均销售额',
                talentLiveSalesNum: '直播累计销售额',
                historyVideos: '历史总视频数',
                baseVideoDateAvg: '平均视频时长',
                baseSalesAvg: '平均销量',
                talentVedioSales: '视频累计销量',
                baseSalesNumAvg: '平均销售额',
                talentVedioSalesNum: '视频累计销售额',
                liveCalendar: '直播日历',
                videoCalendar: '视频日历',
                hasLived1: '开播',
                hasLived2: '场',
                liveDate: '开播时间',
                liveTime: '直播时长',
                liveLookNum: '观看人次',
                liveUserPeak: '人气峰值',
                liveSales: '销量',
                liveSalesNum: '销售额',
                productText1: '近30天，该达人最经常带的商品品类是',
                productText2: '，销量最高的品类是',
                productText3: '；最经常带的商品品牌是',
                productText4: '，销量最高的品牌是',
                baseShopNum: '上架商品数',
                baseBringNum: '直播带货',
                baseCategoryNum: '推广品类',
                baseBrandNum: '推广品牌',
            },
            liveAnalyse: {
                liveBringNum: '带货场次',
                liveWatchNumAvg: '场均观看',
                liveWatchDateAvg: '日均观看',
                liveWatchSum: '累计观看人次',
                liveShopNum: '上架商品',
                liveSalesNumAvg: '场均销售额',
                liveSalesDateAvg: '日均销量额',
                liveSalesSum: '直播累计销售额',
                liveUvNumAvg: '场均UV值',
                liveQuestPrice: '客单价',
                liveUvDateAvg: '日均UV价值',
                liveTrend: '直播趋势分析',
            },
            videoAnalyse: {
                videoNum: '所有视频数',
                videoBringNum: '带货视频数',
                videoLikesAvg: '平均点赞数',
                videoReviewAvg: '平均评论数',
                videoShareAvg: '平均转发数',
                videoFavoritesAvg: '平均收藏数',
                videoSalesAvg: '平均销量',
                talentVedioSales: '总销量',
                videoSalesNumAvg: '平均销售额',
                talentVedioSalesNum: '总销售额',
                indexTrend: '指标趋势分析',
            },
            commerceAnalyse: {
                bringVideos: '视频数量',
                bringClazz: '视频',
                bringLive: '直播带货量',
                bringLiveNum: '带货直播场次',
                bringVideo: '视频带货量',
                bringVideosNum: '带货视频数',
                bringCategory: '带货品类',
                bringSalesNum: '视频带货量',
                bringBrand: '带货品牌',
                liveUvDateAvg: '日均UV价值',
                liveTrend: '直播带货趋势',
            },
            colum:{
                rank: '排行',
                blogger: '达人',
                telentClazz: '达人分类',
                fansNum: '粉丝数',
                talentFollowers: '粉丝总量',
                talentFollowersIncrement: '粉丝增量',
                talentVideo: '视频数量',
                talentLikes: '点赞数',
                talentLikesAvg: '平均点赞数',
                talentFollowerLikeRateAvg: '平均赞粉比',
                telentLive: '直播场次',
                talentLiveViewsAvg: '直播平均场观',
                talentStuckAvg: '平均停留时间',
                talentUvAvg: '平均UV价值',
                talentSalesAvg: '场均销售额',
                talentBringAvg: '带货转换率',
                talentLiveSales: '直播销量',
                talentLiveSalesNum: '直播销售额',
                talentShop: '推广商品数',
                talentVedioSales: '视频销售总额',
                talentIpm: 'IPM',
                telentClazzsName: '团购分类',
                talentSaleAbility: '带货力',
                talentGroupVideo: '团购视频数',
                telentGroupLive: '团购直播场次',
                talentGpm: 'GPM',
                talentVideoGpm: '视频GPM',
                talentLiveGpm: '直播GPM',
                unitPriceCustomers: '带货客单价',
                shopType: '带货类目',
                liveRoom: '直播间',
                estimateLiveSales: '预估销量',
                estimateLiveSalesNum: '预估销售额',
                liveUserPeak: '人气峰值',
                talentAuth: '认证信息',
                mcnName: 'MCN机构',
                mcnTalentNum: '达人增量',
                mcnSales: '销售额',
                mcnClazz: '达人主要分类',
                mcnTalent: 'TOP达人',
                talentBring: '带货方式',
                talentBrief: '达人简介',
                talentUrl: '达人主页',
                nationalRank: '本国排名',
            },
            pkColum: {
                base: '基础数据',
                talentMcn: '所属机构',
                telentClazz: '分类',
                talentFollowers: '粉丝数',
                talentFollowersGroup: '粉丝团',
                video: '视频数据',
                talentVideo: '视频个数',
                talentLikesAvg: '平均视频点赞数',
                talentVideoTimeAvg: '平均视频时长',
                talentFollowerLikeRateAvg: '平均赞粉比',
                talentVideoWeek: '周视频个数',
                telentVideoMonth: '月视频个数',
                talentSalesMonth: '近30天预估销售额',
                productTop5: '近30天TOP5商品',
                live: '直播数据',
                talentLive: '累计直播场次',
                talentLiveSalesAvgMonth: '近30天场均销量',
                talentSalesAvgMonth: '近30天场均销量额',
                talentUvAvgMonth: '近30天场均UV价值',
                talentStuckAvgMonth: '近30天场均停留时长',
                productSaleTop5: '近30天销售额TOP5商品',
                talentBringAvgMonth: '近30天带货转化率',
                talentLiveAvgTime: '平均开播时长',
                talentLiveWeek: '本周场次',
                talentLiveAvgMonth: '月均开播场次',
                talentLiveInteractionMonthAvg: '近30天观众互动率',
                fans: '粉丝数据',
                fansSex: '粉丝性别',
                fansAreaTop3: '区域TOP3',
            }
        },
        video: {
            likeNum: '点赞数',
            reviewNum: '评论数',
            shareNum: '转发数',
            foundVideoL: '共发现',
            foundVideoR: '个视频',
            dataScreening: '数据总览',
            analyze: '详细分析',
            baseAnalyze: '基础分析',
            commentAnalyze: '评论分析',
            audienceAnalyze: '观众分析',
            videoAnalyze: '视频分析',
            contrastAnalyze: '对比分析',
            dagnosisAnalysis: {
                keyIndicators: '关键指标',
                thisVideoLevel: '本条视频水平',
                industryAverage: '行业平均水平',
                IndustryLevel: '所处行业水平',
                videoRank1: '视频排行',
                videoRank2: '近7天发布的同行视频在发布后12小时的表现排名',
            },
            contrastAnalyse: {
                videoAfter: '视频发布后',
                addVideo: '对比视频'
            },
            colum: {
                rank: '排行',
                video: '视频',
                videoDuration: '视频时长',
                videoTime: '发布时间',
                talentName: '达人',
                videoSold: '销量',
                videoSoldNum: '销售额',
                videoLabel: '标签'
            }
        },
        live: {
            celebrityL: '共',
            celebrityR: '场红人直播预告',
            dataCenter: '数据大屏',
            living: '直播中',
            lookNum: '观看人次',
            livingData: '实时大屏',
            showHistoryLive: '查看历史直播数据',
            lived: '已结束',
            historyL: '历史直播',
            historyR: '场',
            historyColum1: '日期',
            historyColum2: '直播带货',
            historyColum3: '开播时间',
            historyColum4: '查看',
            colum: {
                rank: '排行',
                live: '直播',
                talentName: '达人',
                talentFollowers: '粉丝数',
                liveLikes: '点赞数',
                liveDate: '直播时长',
                liveUserPeak: '人气峰值',
                liveLookNum: '观看人数',
                liveShopNum: '商品数',
                liveSales: '销量',
                liveSalesNum: '销售额',
            }
        },
        product:{
            firstDate: '首次上架',
            analyze: '详细分析',
            baseAnalyze: '基础分析',
            bloggerAnalyze: '达人分析',
            liveAnalyze: '直播分析',
            videoAnalyze: '视频分析',
            audienceAnalyze: '受众分析',
            baseEchart: {
                type: '来源',
                percent: '占比',
            },
            blogger:{
                shopLiveNum: '直播达人数',
                shopTalentNum: '视频达人数',
                talentNameTop3: '销量TOP3达人',
                sales: '销量',
                percent: '占比',
                talentType: '达人类型',
                talentTrend: '关联达人趋势',
                talentList: '达人列表',
            },
            live: {
                shopLiveNum: '直播场次',
                shopTalentNum: '直播达人数',
                shopSales: '直播销量',
                shopSalesNum: '直播销售额',
                liveSaleText: '直播销售情况',
                liveList: '直播列表',
                talentName: '达人',
                liveDate: '讲解时长',
                liveSales: '本品销量',
                liveSalesNum: '本品销售额',
                videoLikes: '点击率',
                videoReview: '转化率',
            },
            video: {
                shopVideoNum: '带货视频数',
                shopTalentNum: '视频达人数',
                shopSales: '视频销量',
                shopSalesNum: '视频销售额',
                videoTrend: '视频趋势',
                videoSaleText: '视频销售情况',
                videoist: '视频列表',
                video: '视频',
                talentName: '达人',
                videoTime: '发布时间',
                videoSold: '本品销量',
                videoSalesNum: '本品销售额',
                videoLikes: '点赞数',
                videoReview: '评论数',
                videoShare: '转发数',
            },
            colum: {
                rank: '排行',
                product: '商品',
                shopLikeNum: '佣金比例',
                shopYesterdaySold: '昨日销量',
                shopLiveSold: '直播销量',
                shopVideoSold: '视频销量',
                talentNum: '关联达人',
                liveNum: '关联直播',
                videoNum: '关联视频',
                trend30: '30天销量趋势',
                storeName: '店铺名',
                shopClazz: '商品分类',
                shopSold: '销量',
                shopSoldTotal: '总销量',
                shopSoldNum: '总销售额',
                shopPrice: '最新到手价',
                shopHistoryPrice: '历史最低价',
                shopCommission: '公开佣金',
                pageView30: '近30日浏览量',
                shopSold30: '近30日销量',
                pageView: '浏览量',
                positiveReviewRate: '好评率',
                conversionRate: '转化率'
            }
        },
        shop: {
            colum: {
                rank: '排行',
                storeName: '店铺名',
                storeClazz: '主营类目',
                storeSales: '总销量',
                storeSalesNum: '总销售额',
                storeShopNum: '上架商品',
                storeTalentNum: '关联达人',
                storeVideoNum: '关联视频',
                storeUnitPrice: '客单价',
            }
        }
    },
    vip: {
        all: '全部',
        douyinVipHead: 'TikTok分析平台会员',
        redVipHead: '小红书分析平台会员',
        text1: 'sparkinsight，不止是数据分析',
        doVip: '立即订购',
        text2li1: '实时数据监控',
        text2li2: '热门直播分析',
        text2li3: '专业直播大屏',
        text2li4: '多维数据查询',
        text2li5: '行业报告分析',
        text2li6: '更多收藏权益',
        text2li7: '会员特权功能',
        text2li8: '优质资源对接',
        vipContrast: '会员特权对比',
        privilege: '功能特权',
        b_vip: '品牌版会员',
        b_vip_text: '适合品牌 / 机构洞察行业了解竞对',
        myVipHead: '您已经是',
        p_vip: '旗舰版会员',
        p_vip_text: '适用企业/品牌深度运营',
        m_vip: '专业版会员',
        m_vip_text: '适用企业/达人/MCN团队运营',
        i_vip: '个人版会员',
        i_vip_text: '适用个人初级运营者',
        searchTimes: '搜索次数',
        unlimited: '不限',
        time_day: '次/天',
        day_week_month: '日/周/月榜',
        viewDetails: '查看详情页',
        liveRank: '直播榜',
        liveMonitor: '直播监控',
        bloggerData: '达人数据',
        bloggerRank: '达人榜',
        E_commerceData: '电商数据',
        productRank: '商品榜',
        shopRank: '小店榜',
        brand: '品牌库',
        brandRank: '品牌榜',
        videoData: '视频数据',
        videoRank: '视频榜',
        videoMonitor: '视频监控',
        commonQA: '常见问题',
        vipEndDateLeft: '您的',
        vipEndDateMid: '还剩',
        vipEndDateRight: '天过期',
        limitedTime: '限时',
        discount: '折',
        totalPrice: '总价',
        payType: '支付方式',
        payPrice: '支付金额',
        activateNow: '立即开通',
        hasQuestion: '有疑问？联系客服',
    }
}
