export default {
    nation: {
        jpn: '日本',
        japan: '日本',
        indonesia: 'インドネシア',
        america: '米国',
        britain: '英国',
        vietnam: 'ベトナム',
        thailand: 'タイ'
    },
    loading: '統計的に...',
    noData: 'no date',
    head: {
        TikTok: 'TikTok',
        Instagram: 'Instagram',
        YouTube: 'YouTube',
        douyin: '抖音',
        kuaishou: '快手',
        xiaohongshu: 'RED',
        aboutUs: '会社概要',
        personalCenter: 'アカウント',
        blogger: '達人探す',
        bloggerText: '達人ドッキング',
        live: 'ライブ探す',
        liveText: '人気スタジオ',
        product: '製品探す',
        productText: '高手数料の爆人気商品探す',
        video: 'ビデオ探す',
        videoText: 'ランキング一番',
        shop: 'ショップ探す',
        shopText: '売上高一番',
        lang: '日本語',
        chinese: '中文',
        japanese: '日本語',
        english: 'English',
        indonesian: 'インドネシア語',
        registerAndLogin: '注册/登录',
        purchaseRenewal: '购买续费',
        myCollection: 'マイコレクション',
        personalInformation: '情報と認証',
        helps: '帮助中心'
    },
    login: {
        login: 'ログイン',
        register: '登録へようこそ',
        registerEmail: 'メールアドレス',
        registerPassword: 'パスワード',
        toLogin: 'ログイン',
        sendCode: '認証コード取得',
        registerSmsCode: '認証コードを入力してください',
        registerCompany: '会社名',
        registerPhone: '電話番号',
        registerVerified: 'パスワード再入力してください',
        toRegister: '登録完了'
    },
    index: {
        TikTok: 'TikTok',
        Instagram: 'Instagram',
        YouTube: 'YouTube',
        douyin: '抖音',
        kuaishou: '快手',
        xiaohongshu: 'RED',
        mainDiv1_2_l: 'ショートビデオ、ライブコマース販売、達人',
        mainDiv1_2_r: 'データ分析プラットフォーム',
        mainSearch: '達人/商品/ライブ放送/ショップ/ビデオ/ブランドを検索',
        mainDiv1_4: 'ライプ販売リプレイ・レビュー及びマーケティング診断',
        mainDiv1_5_1: 'ライブ販売運営管理ツール、スタジオスチュワードになります,',
        mainDiv1_5_2: 'トラフィック配信、商品分析、業界比較などの総合的な指標をリアルタイムで追跡します,',
        mainDiv1_5_3: 'また、録画と再生を通じて、ライブ販売プロセスの確認および診断できます,',
        mainDiv1_5_4: 'AI スケジューリング、アンカーパフォーマンスなどの機能もあり、ライブブロードキャストチームを効率的に管理できます。',
        mainDiv2_head: '今日のライブストリーミングのトレンド',
        saleNumHeat: '販売人気度',
        audienceHeat: '視聴者人気度',
        liveHeat: 'スタジオ人気度',
        productHeat: '商品人気度',
        saleHeat: '販売人気度',
        mainDiv3_title: '商品選定とライブ販売・爆人気商品探す',
        mainDiv3_1: '商品選定とライブ販売',
        mainDiv3_2: 'オンラインの高額爆発物製品',
        mainDiv3_3: '取引完成オーダー',
        mainDiv4_title: 'フルシーンマーケティングサービス',
        mainDiv4_1eft_1_1: '基本データ',
        mainDiv4_1eft_1_2: 'データ達人',
        mainDiv4_1eft_2_2: '商品データ',
        mainDiv4_1eft_3_1: 'サポート',
        mainDiv4_1eft_3_2: 'ライブデータ',
        mainDiv4_1eft_4_2: 'ビデオコマース ',
        mainDiv4_1eft_5: '安心アップデート',
        mainDiv4_right_title_1: '基本ビデオコマース情報',
        mainDiv4_right_text_1: '各プラットフォームの販売中の製品情報を表示します。主に基本情報、販売数量、レビュー情報、販売提携達人、販売中店舗などの情報を含みます。',
        mainDiv4_right_title_2: 'ライブデータサポート',
        mainDiv4_right_text_2: '指定したスタジオのインデックス作成と更新をサポート',
        mainDiv4_right_title_3: '商品基本データ',
        mainDiv4_right_text_3: '各プラットフォームのビデオコマース素材を表示します。ビデオの評価、素材、ミュージック、トピックスなど、さまざまな側面のリサーチをサポートします。',
        mainDiv4_right_title_4: '安心アップデート',
        mainDiv4_right_text_4: '毎日安心、安定した更新し、さらに必要に応じて、対応するショートビデオ、スラジオ及びその他の関連データをクエリできます。',
        mainDiv4_right_title_5: '基本データ達人',
        mainDiv4_right_text_5: '各プラットフォームの公開した達人情報を表示します。フォロワー情報、コンテンツのリリース記録、コンテンツのインタラクション率、過去のライブ販売記録などをクエリできます。さまざまな国のインフルエンサーリストをフィルタリングをサポートします。',
    },
    left: {
        blogger: 'インフルエンサーランキング',
        bloggerLive: 'ライブコマース（24時間）',
        bloggerRise: 'フォロワー急上昇（24時間）',
        bloggerMCN: 'MCN機構',
        video: '動画再生回数ランキング',
        videoCommerce: '物販動画',
        videoHot: '人気動画',
        videoGroupBuy: '共同購入動画',
        live: 'ライブコマースランキング',
        liveToday: '本日売り上げ',
        liveHour: '時間帯別',
        liveCelebrity: 'コントロールパネル',
        product: '商品ランキング',
        productRank: '売り上げ高',
        productHot: '人気商品',
        shop: 'ショップランキング',
        shopRank: 'ショップ',
        shopBrandRank: '人気ブランドショップ',
    },
    aboutUs: {
        mainDiv1: '価値がある洞察及びワンストップソリューションを提供する',
        mainDiv2_1: '会社概要',
        mainDiv2_2: 'スパックインサイトはショットビデオのデータ分析を中心としたプラットフォームです。私たちのミションは、お客様がビッグデータを分析および活用できるように支援し、価値がある洞察及びワンストップソリューションを提供することです。また、データ分析分野のプロフェッショナルチームとして、豊富な経験と深い専門知識を持っています。',
        mainDiv2_3: 'スパックインサイトはショットビデオの生態系を深く理解しており、最新のテクノロジーとツールを駆使し、ビッグデータにおいての重要な情報を正確に取得して解析できます。当プラットフォームの強みはデータ分析力と洞察力であります。',
        mainDiv2_4: 'スパックインサイトは、高度なアルゴリズムとテクノロジーを使用してショートビデオプラットフォーム上のデータを深く掘り下げて分析し、潜在的なトレンド、ユーザー行動、ビジネスチャンスを明らかにします。',
        MainDiv3_1: 'スパックインサイトの分析レポートおよび視覚化ツールでは、クライアントがデータをより深く理解し、情報に基づいた意思決定を行い、ビジネス戦略を最適化するのに役立ちます。',
        MainDiv3_2: '当プラットフォームは顧客中心であり、顧客との協力とコミュニケーションに重点を置いています。',
        MainDiv3_3: '当プラットフォームはお客様のニーズと目標を深く理解し、お客様に合わせたソリューションを提供し、専門的なアドバイスとサポートを提供します。当プラットフォームは、ビジネスの成長と成功を達成するために、お客様との長期的な関係を築くことに全力を尽くしています。',
        MainDiv3_4: 'プロのショートビデオデータ分析パートナーをお探しなら、スパックインサイトが理想的な選択肢となります。',
        MainDiv3_5: 'ぜひご連絡ください。データに基づいた洞察の旅を一緒に始めましょう。',
    },
    personalCenter: {
        title: 'アカウント',
        tab1: '情報と認証',
        tab2: 'マイコレクション',
        tab2_1: '達人',
        tab2_2: 'ビデオ',
        tab2_3: '製品',
        tab2_4: 'トピック',
        tab3: 'ビデオライブラリ',
        tab4: 'ライブライブラリ',
        tab5: '商品ライブラリー',
        tab6: 'ショップライブラリー',
        tab7: '达人管理',
        tab8: '商品管理',
        personalMainTitle: '情報',
        emailBinding: 'メールボックスバインド',
        toBind: 'バインド更新',
        phoneBind: '携帯電話バインド',
        password: 'ログインパスワード',
        change: '変更',
        purchaseRenewal: '购买续费',
        myOrder: '我的订单',
        myBloggers: '我的达人',
        bloggerList: '达人公海',
        productList: '商品ライブラリー',
        category: '商品カテゴリー'
    },
    tiktok: {
        like: 'いいね',
        comment: 'コメント',
        share: '転送',
        collect: 'ブックマーク',
        sales: '販売数量',
        salesNum: '売上高',
        hour: '時間',
        yesterday: '昨日',
        day: '日',
        add: '追加',
        addVideo: 'ビデオ追加',
        GPM: '売上高/千回',
        IPM: 'インタラクション/千回',
        liveDataCenter: {
            fans: 'フォロワー',
            liveDate: '開始日時',
            liveTime: 'ライブ時間',
            lookingNum: 'リアルタイム視聴',
            UVDate: 'UV値',
            stuckAvg: '平均滞在時間',
            fansIncrease: 'フォロワー累積増加数',
            powderConversionRate: 'フォロワー変換率',
            productNum: 'アイテム数',
            saleTotal: '総売上高',
            product: '商品',
            price: 'ライブ価格',
            liveWatchSum: '累計視聴者数',
        },
        class: {
            nation: '国・地域',
            blogger: '達人カテゴリー',
            product: '商品カテゴリー',
            fans: 'フォロワー区間',
            productNum: '商品案内数量',
            productVideoNum: '商品案内ビデオ本数',
            listSelection: 'リストの選択',
            conditionalSelection: '条件フィルター',
            otherSelection: 'その他フィルター',
            audience: '視聴者画像',
            sortType: '並び替え',
            timeSelection: '時間フィルター',
            productInfo: '商品情報',
            commissionInfo: 'コミッション情報',
            carryingInfo: '商品案内情報',
            carryingType: '商品案内方法',
            to: '～',
            startDate: '開始時間',
            endDate: '終了時間',
            operate: '操作',
            min: '最小値',
            max: '最大値',
            dateSearchPlaceholder: '日付選択',
            day: '毎日ランキング',
            week: '週間ランキング',
            month: '月間ランキング',
            recentDetails: '詳細情報',
            getTouch: '連絡する',
            searchPlaceholder: '内容を入力してください',
            sex: '性別',
            age: '年齢',
            fan: 'フォロワー',
            sales: '販売数',
            detail: '詳細',
            openVideo: '放送',
            bySales: '販売数',
            bySalesNum: '売上高',
            byLike: 'いいね',
            byDefault: '総合',
            liveSale: 'ライブ販売',
            liveGroup: 'シェア買いライブ',
            newStar: '新星',
            firstRank: '初めてランキングに載った',
            darkHorse: '実力者',
            unlimited: '制限無し',
            freeShipping: '送料無料',
            coupon: 'クーポン付き',
            commissionRate: '手数料率',
            pageView: 'ビュー',
            typeSales: '販売',
            favorableRate: '好評率',
            relatedExperts: '関連達人',
            audiencePortrait: '観客のポートレート',
            mainlyLiveDelivery: 'ライブコマース販売がメイン',
            mainlyVideoDelivery: 'ビデオ販売がメイン',
            lessThan1W: '1W以内'
        },
        blogger: {
            search: '達人探す',
            searchPlaceholder: '内容を入力してください',
            tabAll: '全て',
            tabLive: 'ライブ達人',
            tabVideo: 'ビデオ達人',
            tabProduct: 'シェア買い達人',
            listTip: '次の表示は、選択したカテゴリに所属する達人の過去 30 日間のデータです',
            pk: '達人PK',
            clear: 'クリア',
            toPk: '比較',
            pkDetail: '達人PK詳細',
            addBlogger: '意向の達人として追加',
            collect: 'ブックマーク',
            dataScreening: 'データ概要',
            analyze: '詳細分析',
            baseAnalyze: '基本分析',
            liveAnalyze: 'ライブ分析',
            videoAnalyze: 'ビデオ分析',
            commerceAnalyze: '商品販売分析',
            fansAnalyze: 'フォロワー分析',
            fansPortrait: 'フォロワー画像',
            baseAnalyse: {
                title: '過去 30 日間のデータ概要',
                text1: '過去 30 日間で、この達人は',
                text2: '回ライブコマースをし、新しいビデオ',
                text3: '本をリリースし、フォロワー数が',
                text4: '人を増加しました。 累計売上高',
                text5: '達成（ライブ放送比率',
                text6: '）、30日間累計売上高',
                text7: '達成（ライブ放送比率',
                historyLiveNum: 'ライブ回数合計',
                baseLiveDateAvg: 'ライブ平均時間',
                baseLiveSalesAvg: '平均１回販売数',
                liveSalesDateAvg: '平均１日販売数',
                talentLiveSales: 'ライブ累計販売数',
                baseLiveSalesNumAvg: '平均１回売上高',
                liveSalesNumDateAvg: '平均１日売上高',
                talentLiveSalesNum: '累計ライブ売上高',
                historyVideos: 'ビデオ本数合計',
                baseVideoDateAvg: '平均ビデオ時間',
                baseSalesAvg: '平均販売数',
                talentVedioSales: 'ビデオ売上',
                baseSalesNumAvg: 'ビデオ平均売上高',
                talentVedioSalesNum: 'ビデオ累計売上高',
                liveCalendar: 'ライブカレンダー',
                videoCalendar: 'ビデオカレンダー',
                hasLived1: '',
                hasLived2: '回のライブ配信',
                liveDate: '開始日時',
                liveTime: 'ライブ時間',
                liveLookNum: '視聴回数',
                liveUserPeak: 'ピーク人気値',
                liveSales: '販売数',
                liveSalesNum: '売上高',
                productText1: '過去 30 日間で、この達人が最も頻繁にライブ販売した製品のカテゴリは',
                productText2: 'で、最も売れたカテゴリは',
                productText3: 'でした。最も頻繁にライブ販売した製品ブランドは',
                productText4: 'で、最も売れたブランドは',
                baseShopNum: 'です。出品商品数',
                baseBringNum: 'ライブ案内',
                baseCategoryNum: 'プロモーションカテゴリー',
                baseBrandNum: 'プロモーションブランド',
            },
            liveAnalyse: {
                liveBringNum: 'ライブ販売回数',
                liveWatchNumAvg: '平均視聴/回',
                liveWatchDateAvg: '平均視聴/日',
                liveWatchSum: '累計視聴者数',
                liveShopNum: '出品商品',
                liveSalesNumAvg: '平均売上高/回',
                liveSalesDateAvg: '平均売上高/日',
                liveSalesSum: 'ライブ販売累計売上高',
                liveUvNumAvg: '平均 UV 値/回',
                liveQuestPrice: '客単価',
                liveUvDateAvg: '平均UV値/日',
                liveTrend: 'ライブトレンド分析',
            },
            videoAnalyse: {
                videoNum: 'ビデオ総本数',
                videoBringNum: 'ライブ販売ビデオ本数',
                videoLikesAvg: '平均いいね数',
                videoReviewAvg: '平均コメント数',
                videoShareAvg: '平均転送数',
                videoFavoritesAvg: '平均コレクション',
                videoSalesAvg: '平均販売数',
                talentVedioSales: '総販売数量',
                videoSalesNumAvg: '平均売上高',
                talentVedioSalesNum: '売上高総額',
                indexTrend: '指標トレンド分析',
            },
            commerceAnalyse: {
                bringVideos: 'ビデオ数量',
                bringClazz: 'ビデオ',
                bringLive: 'ライブ販売金額',
                bringLiveNum: 'ライプ販売回数',
                bringVideo: 'ライブ販売金額',
                bringVideosNum: 'ビデオ本数',
                bringCategory: '販売カテゴリー',
                bringSalesNum: 'ビデオ販売金額',
                bringBrand: '販売ブランド',
                liveUvDateAvg: '平均UV値/日',
                liveTrend: 'ライブ販売トレンド',
            },
            colum:{
                rank: 'ランキング',
                blogger: '達人',
                telentClazz: '達人分類',
                fansNum: 'フォロワー数',
                talentFollowers: 'フォロワー総数',
                talentFollowersIncrement: 'フォロワー増加数',
                talentVideo: 'ビデオ本数',
                talentLikes: 'いいね数量',
                talentLikesAvg: '平均いいね',
                talentFollowerLikeRateAvg: '平均いいねフォロワー率',
                telentLive: 'ライブ回数',
                talentLiveViewsAvg: 'ライブ平均ビュー',
                talentStuckAvg: '平均滞在時間',
                talentUvAvg: '平均UV値',
                talentSalesAvg: '平均売上高',
                talentBringAvg: 'ライブ販売転換率',
                talentLiveSales: 'ライブ販売数',
                talentLiveSalesNum: 'ライブ販売金額',
                talentShop: 'プロモーション商品数',
                talentVedioSales: 'ライブ販売総売上高',
                talentIpm: 'IPM',
                telentClazzsName: 'シェア買い分類',
                talentSaleAbility: 'ライブ販売力',
                talentGroupVideo: 'シェア買いビデオ本数',
                telentGroupLive: 'シェア買いライブ回数',
                talentGpm: 'GPM',
                talentVideoGpm: 'ビデオGPM',
                talentLiveGpm: 'ライブGPM',
                unitPriceCustomers: 'ライプ販売単価',
                shopType: 'ライプ販売カテゴリー',
                liveRoom: 'スタジオ',
                estimateLiveSales: '推定販売数量',
                estimateLiveSalesNum: '推定売上高',
                liveUserPeak: '人気ピーク値',
                talentAuth: '認証情報',
                mcnName: 'MCN機関',
                mcnTalentNum: '達人増加数',
                mcnSales: '販売金額',
                mcnClazz: '達人カテゴリー',
                mcnTalent: 'TOP達人',
                talentBring: 'ライブ販売方法',
                talentBrief: '達人プロフィール',
                talentUrl: '達人ホーム',
                nationalRank: '全国ランク',
            },
            pkColum: {
                base: '基本データ',
                talentMcn: '所属MCN',
                telentClazz: 'カテゴリー',
                talentFollowers: 'フォロワー数',
                talentFollowersGroup: 'フォロワー会',
                video: 'ビデオデータ',
                talentVideo: 'ビデオ本数',
                talentLikesAvg: 'ビデオ平均いいね数',
                talentVideoTimeAvg: 'ビデオ平均時長',
                talentFollowerLikeRateAvg: '平均いいねフォロワー率',
                talentVideoWeek: 'ビデオ本数/週',
                telentVideoMonth: 'ビデオ本数/月',
                talentSalesMonth: ' 30日間推定売上高',
                productTop5: '30日間TOP5 製品',
                live: 'ライブデータ',
                talentLive: '累積ライブ回数',
                talentLiveSalesAvgMonth: '30日間平均販売数量',
                talentSalesAvgMonth: '30日間平均販売金額',
                talentUvAvgMonth: '30日間平均 UV 値',
                talentStuckAvgMonth: '30日間フォロワー平均滞在時長',
                productSaleTop5: '30日間TOP5製品',
                talentBringAvgMonth: '30日間ライブ販売転換率',
                talentLiveAvgTime: '平均ライプ時長',
                talentLiveWeek: '今週ライブ回数',
                talentLiveAvgMonth: '月あたりライブ回数',
                talentLiveInteractionMonthAvg: '30日間視聴者のインタラクション率',
                fans: 'フォロワーデータ',
                fansSex: 'フォロワー性別',
                fansAreaTop3: '地域TOP3',
            }
        },
        video: {
            likeNum: 'いいね数',
            reviewNum: 'コメント数',
            shareNum: '転送数',
            foundVideoL: '合計',
            foundVideoR: '本のビデオが見つかりました',
            dataScreening: 'データ概要',
            analyze: '詳細分析',
            baseAnalyze: '基本分析',
            commentAnalyze: 'コメント分析',
            audienceAnalyze: '視聴者分析',
            videoAnalyze: 'ビデオ分析',
            contrastAnalyze: '比較解析',
            dagnosisAnalysis: {
                keyIndicators: 'キーポイント',
                thisVideoLevel: 'このビデオレベル',
                industryAverage: '業界平均レベル',
                IndustryLevel: '業界レベル',
                videoRank1: 'ビデオランキング',
                videoRank2: '過去7日間同業者ビデオ投稿後12時間以内のパフォーマンスランキング',
            },
            contrastAnalyse: {
                videoAfter: 'ビデオ投稿後',
                addVideo: '比較ビデオ'
            },
            colum: {
                rank: 'ランキング',
                video: 'ビデオ',
                videoDuration: 'ビデオ時長',
                videoTime: 'リリース時間',
                talentName: '達人',
                videoSold: '販売数量',
                videoSoldNum: '売上高',
                videoLabel: 'ラベル'
            }
        },
        live: {
            celebrityL: '合計',
            celebrityR: '回人気インフルエンサーライブ予告',
            dataCenter: 'データスクリーン',
            living: 'ライブ',
            lookNum: '視聴者数',
            livingData: 'リアルタイムデータ',
            showHistoryLive: '過去のライブデータ表示',
            lived: 'ライブ終了',
            historyL: 'ライブ履歴',
            historyR: '回',
            historyColum1: '日付',
            historyColum2: 'ライブ販売',
            historyColum3: '開始時間',
            historyColum4: 'ビュー',
            colum: {
                rank: 'ランキング',
                live: 'ライブ',
                talentName: '達人',
                talentFollowers: 'フォロワー数',
                liveLikes: 'いいね数',
                liveDate: 'ライブ時長',
                liveUserPeak: '人気ピーク値',
                liveLookNum: '視聴者数',
                liveShopNum: 'アイテム数',
                liveSales: '販売数量',
                liveSalesNum: '売上高',
            }
        },
        product:{
            firstDate: '初めて出品',
            analyze: '詳細分析',
            baseAnalyze: '基本分析',
            bloggerAnalyze: '達人分析',
            liveAnalyze: 'ライブ分析',
            videoAnalyze: 'ビデオ分析',
            audienceAnalyze: '視聴者分析',
            baseEchart: {
                type: 'ソース',
                percent: '割合',
            },
            blogger:{
                shopLiveNum: 'ライブ達人数',
                shopTalentNum: 'ビデオ達人数',
                talentNameTop3: '売上TOP3達人',
                sales: '販売数量',
                percent: '割合',
                talentType: '達人タイプ',
                talentTrend: '関連達人トレンド',
                talentList: '達人リスト'
            },
            live: {
                shopLiveNum: 'ライブ回数',
                shopTalentNum: 'ライブ達人数',
                shopSales: 'ライブ販売数量',
                shopSalesNum: 'ライブ売上高',
                liveSaleText: 'ライブ販売ステータス',
                liveList: 'ライブリスト',
                talentName: '達人',
                liveDate: '説明時長',
                liveSales: '商品販売数量',
                liveSalesNum: '商品売上高',
                videoLikes: 'クリック率',
                videoReview: '転換率',
            },
            video: {
                shopVideoNum: 'ビデオ本数',
                shopTalentNum: 'ビデオ達人数',
                shopSales: 'ビデオ販売数量',
                shopSalesNum: 'ビデオ売上高',
                videoTrend: 'ビデオトレンド',
                videoSaleText: 'ビデオ販売ステータス',
                videoist: 'ビデオリスト',
                video: 'ビデオ',
                talentName: '達人',
                videoTime: 'リリース時間',
                videoSold: '販売数量',
                videoSalesNum: '売上高',
                videoLikes: 'いいね数',
                videoReview: 'コメント数',
                videoShare: '転送数',
            },
            colum: {
                rank: 'ランキング',
                product: '商品',
                shopLikeNum: '手数料率',
                shopYesterdaySold: '昨日売上',
                shopLiveSold: 'ライブ販売',
                shopVideoSold: 'ビデオ販売',
                talentNum: '関連達人',
                liveNum: '関連ライブ',
                videoNum: '関連ビデオ',
                trend30: '30日間売上推移',
                storeName: 'ショップ名',
                shopClazz: 'カテゴリー',
                shopSold: '販売数',
                shopSoldTotal: '総販売数',
                shopSoldNum: '総売上高',
                shopPrice: '最新入手価格',
                shopHistoryPrice: '最低履歴価格',
                shopCommission: '公開手数料',
                pageView30: '過去30 日間閲覧数',
                shopSold30: '過去 30 日間売上',
                pageView: '閲覧数',
                positiveReviewRate: '好評率',
                conversionRate: '転換率'
            }
        },
        shop: {
            colum: {
                rank: 'ランキング',
                storeName: 'ショップ名',
                storeClazz: 'メインカテゴリー',
                storeSales: '総販売数',
                storeSalesNum: '総売上高',
                storeShopNum: '出品商品',
                storeTalentNum: '関連達人',
                storeVideoNum: '関連ビデオ',
                storeUnitPrice: '客単価',
            }
        }
    },
    vip: {
        all: '全て',
        douyinVipHead: 'TikTok分析平台会员',
        redVipHead: '小红书分析平台会员',
        text1: 'sparkinsight，不止是数据分析',
        doVip: '立即订购',
        text2li1: '实时数据监控',
        text2li2: '热门直播分析',
        text2li3: '专业直播大屏',
        text2li4: '多维数据查询',
        text2li5: '行业报告分析',
        text2li6: '更多收藏权益',
        text2li7: '会员特权功能',
        text2li8: '优质资源对接',
        vipContrast: '会员特权对比',
        privilege: '功能特权',
        b_vip: '品牌版会员',
        b_vip_text: '适合品牌 / 机构洞察行业了解竞对',
        myVipHead: '您已经是',
        p_vip: '旗舰版会员',
        p_vip_text: '适用企业/品牌深度运营',
        m_vip: '专业版会员',
        m_vip_text: '适用企业/达人/MCN团队运营',
        i_vip: '个人版会员',
        i_vip_text: '适用个人初级运营者',
        searchTimes: '搜索次数',
        unlimited: '制限無し',
        time_day: '次/天',
        day_week_month: '日/周/月榜',
        viewDetails: '查看详情页',
        liveRank: '直播榜',
        liveMonitor: '直播监控',
        bloggerData: '达人数据',
        bloggerRank: '达人榜',
        E_commerceData: '电商数据',
        productRank: '商品榜',
        shopRank: '小店榜',
        brand: '品牌库',
        brandRank: '品牌榜',
        videoData: '视频数据',
        videoRank: '视频榜',
        videoMonitor: '视频监控',
        commonQA: '常见问题',
        vipEndDateLeft: '您的',
        vipEndDateMid: '还剩',
        vipEndDateRight: '天过期',
        limitedTime: '限时',
        discount: '折',
        totalPrice: '总价',
        payType: '支付方式',
        payPrice: '支付金额',
        activateNow: '立即开通',
        hasQuestion: '有疑问？联系客服',
    }
}