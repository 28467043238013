<template>
    <router-view></router-view>

    <div class="rightFix">
        <div class="qrMain" style="border-bottom: 2px solid #e7e7e7;position: relative;">
            <img class="fixImg" src="@/assets/qrcodeRight.png"/>
            <div class="qrMessage">
                <img src="@/assets/sparkQrCode.png"/>
            </div>
        </div>
        <div style="cursor: pointer;" @click="showMessage">
            <img class="fixImg" src="@/assets/serviceRight.png" />
        </div>
        <el-backtop :bottom="100" :right="0">
            <div class="toTop">
                <img class="fixImg" src="@/assets/topRight.png" />
            </div>
        </el-backtop>
    </div>

    <div class="message" v-if="submitShow == true">
        <div class="title">请您留言</div>
        <img style="position: absolute;top: 10px;right: 10px;cursor: pointer;" src="@/assets/closeWhite.png" @click="closeMessage"/>
        <div style="padding: 10px;">
            <div style="margin: 5px 0;">您好，当前人工客服繁忙，请留下您的需求和联系方式，我们会尽快联系您。</div>

            <el-input
                v-model="context"
                :rows="4"
                resize="none"
                type="textarea"
                placeholder="请在此输入留言内容，我们会尽快与您联系。(必填)"
                style="margin-top: 10px;"
            />
            <el-input
                v-model="userName"
                size="large"
                placeholder="姓名"
                style="margin-top: 10px;"
            />
            <el-input
                v-model="userPhone"
                size="large"
                placeholder="电话(必填)"
                style="margin-top: 10px;"
            />
            <el-input
                v-model="companyName"
                size="large"
                placeholder="公司名(必填)"
                style="margin-top: 10px;"
            />
        </div>

        <div class="submit" @click="toSubmit">提交</div>
    </div>

</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: 'App',
  components: {

  },
  data(){
      return{
          submitShow: false,
          context:'',
          userName:'',
          userPhone:'',
          companyName:''
      }
  },
  methods:{
      showMessage(){
          this.submitShow = true;
      },
      closeMessage(){
          this.submitShow = false;
          this.context = "";
          this.userName = "";
          this.userPhone = "";
          this.companyName = "";
      },
      toSubmit(){
          if(this.context == '' || this.userPhone == '' || this.companyName == ''){
              ElMessage({message: '必填内容为空',type: 'warning'});
          }else{
              this.submitShow = false;
              this.context = "";
              this.userName = "";
              this.userPhone = "";
              this.companyName = "";
          }
      }
  }
}
</script>

<style>
  body{
      margin: 0;
      background-color: rgba(90, 90, 90, 0.1);
  }

  .rightFix{
      z-index: 99999;
      position: fixed;
      right: 0;
      bottom: 143px;
      width: 44px;
      height: 94px;
      background-color: white;
      box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.1);
  }
  .rightFix .fixImg{
      width: 20px;
      height: 20px;
      margin: 12px;
  }
  .qrMain:hover{
     .qrMessage{
         display: block!important;
     }
  }
  .qrMessage{
      display: none;
      position: absolute;
      top: -105px;
      left: -165px;
      background-color: white;
      border-radius: 10px;
  }
  .qrMessage img{
      width: 150px;
      height: 150px;
      padding: 5px;
  }
  .toTop{
      position: fixed;
      right: 0;
      width: 44px;
      height: 44px;
      background-color: white;
      box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.1);
  }
  .messageIndex {
      z-index: 9999 !important;
  }

  .message{
      z-index: 99999;
      position: fixed;
      right: 1px;
      bottom: 100px;
      width: 280px;
      height: 450px;
      background-color: white;
      box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
  }
  .message .title{
      font-size: 20px;
      line-height: 50px;
      height: 50px;
      background-color: rgb(236 114 16);
      color: white;
      border-radius: 10px 10px 0 0;
      padding-left: 20px;
  }
  .message .submit{
      cursor: pointer;
      float: right;
      width: 80px;
      height: 30px;
      line-height: 30px;
      background-color: rgb(236 114 16);
      text-align: center;
      border-radius: 10px;
      color: white;
      margin-right: 20px;
  }
</style>
